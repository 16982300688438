<template>
  <div data-accordion="collapse" class="my-6">
    <div class="relative shadow-lg dark:shadow-gray-800 rounded-md overflow-hidden">
      <h2 class="text-base font-semibold" id="accordion-collapse-heading-13">
        <button
          type="button"
          class="flex justify-between items-center p-5 w-full font-medium text-start"
          aria-expanded="true"
          aria-controls="accordion-collapse-body-13"
          @click="open = !open"
        >
          <span>{{ title }}</span>


          <mdicon :class="{ 'transform rotate-180': open }" class="text-slate-400 dark:text-gray-400" name="ChevronDown"></mdicon> 
        </button>
      </h2>
      <div v-show="open">
        <div class="p-5">
          <slot class="text-slate-400 dark:text-gray-400" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  title: String,
});

let open = ref(false);
</script>
